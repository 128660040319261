var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-titles"},[_c('div',{class:[_vm.isMobile ? 'col-7' : 'col-8', 'align-self-center']},[_c('h3',{staticClass:"text-themecolor m-b-0 m-t-0"},[_vm._v(_vm._s(_vm.title))]),(!_vm.isMobile)?_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[(_vm.header === 'home' || _vm.header === 'Home')?_c('router-link',{attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.header))]):_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v(_vm._s(_vm.header))])],1),_c('li',{class:[
            'breadcrumb-item',
            { active: !_vm.state.isAdd && !_vm.state.isEdit && !_vm.state.isDetail } ]},[_vm._v("\n          "+_vm._s(_vm.title)+"\n        ")]),(_vm.state.isAdd)?_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Add")]):_vm._e(),(_vm.state.isEdit)?_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Edit")]):_vm._e(),(_vm.state.isDisposisi)?_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("\n          Disposisi\n        ")]):_vm._e(),(_vm.state.isDetail)?_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Detail")]):_vm._e()]):_vm._e()]),_c('div',{class:[_vm.isMobile ? 'col-5' : 'col-4', 'align-self-center']},[(_vm.isAdmin)?_c('button',{staticClass:"\n          right-side-toggle\n          waves-effect waves-light\n          btn-info btn-circle btn-sm\n          pull-right\n          m-l-10\n        ",on:{"click":_vm.actionRightSidebar}},[_c('i',{staticClass:"ti-settings text-white"})]):_vm._e(),(
          !_vm.state.isAdd &&
          !_vm.state.isEdit &&
          !_vm.state.isDetail &&
          !_vm.state.isDisposisi &&
          !!_vm.addLink &&
          !_vm.forbidden
        )?_c('button',{class:[
          'btn',
          'pull-right',
          { 'btn-sm btn-circle': _vm.isMobile },
          'btn-success' ],on:{"click":_vm.addLink}},[_c('i',{staticClass:"ti-pencil text-white"}),_vm._v("\n        "+_vm._s(_vm.isMobile ? "" : "Tambah")+"\n      ")]):_vm._e()])]),_c('div',{class:['right-sidebar', { 'shw-rside': _vm.showRightSidebar }],staticStyle:{"display":"block"}},[_c('div',{staticClass:"slimScrollDiv",staticStyle:{"position":"relative","overflow":"hidden","width":"auto"}},[_c('div',{staticClass:"slimscrollright",staticStyle:{"overflow":"hidden","width":"auto"}},[_c('div',{staticClass:"rpanel-title"},[_vm._v("\n          References Panel\n          "),_c('span',{on:{"click":_vm.actionRightSidebar}},[_c('i',{staticClass:"ti-close right-side-toggle"})])]),_c('div',{staticClass:"r-panel-body"},[_c('ul',{staticClass:"m-t-20 chatonline scrolled"},_vm._l((_vm.menuPermissions),function(row,index){return _c('li',{key:index},[_c('router-link',{attrs:{"to":row.path}},[_vm._v(_vm._s(row.title))])],1)}))])]),_c('div',{staticClass:"slimScrollBar",staticStyle:{"background":"rgb(220, 220, 220)","width":"5px","position":"absolute","top":"0px","opacity":"0.4","display":"none","border-radius":"7px","z-index":"99","right":"1px","height":"395.101px"}}),_c('div',{staticClass:"slimScrollRail",staticStyle:{"width":"5px","height":"100%","position":"absolute","top":"0px","display":"none","border-radius":"7px","background":"rgb(51, 51, 51)","opacity":"0.2","z-index":"90","right":"1px"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }