<template>
  <div>
    <div class="row page-titles">
      <div :class="[isMobile ? 'col-7' : 'col-8', 'align-self-center']">
        <h3 class="text-themecolor m-b-0 m-t-0">{{ title }}</h3>
        <ol class="breadcrumb" v-if="!isMobile">
          <li class="breadcrumb-item">
            <router-link v-if="header === 'home' || header === 'Home'" to="/">{{
              header
            }}</router-link>
            <a v-else href="javascript:void(0)">{{ header }}</a>
          </li>
          <li
            :class="[
              'breadcrumb-item',
              { active: !state.isAdd && !state.isEdit && !state.isDetail },
            ]"
          >
            {{ title }}
          </li>
          <li class="breadcrumb-item active" v-if="state.isAdd">Add</li>
          <li class="breadcrumb-item active" v-if="state.isEdit">Edit</li>
          <li class="breadcrumb-item active" v-if="state.isDisposisi">
            Disposisi
          </li>
          <li class="breadcrumb-item active" v-if="state.isDetail">Detail</li>
        </ol>
      </div>
      <div :class="[isMobile ? 'col-5' : 'col-4', 'align-self-center']">
        <button
          v-if="isAdmin"
          class="
            right-side-toggle
            waves-effect waves-light
            btn-info btn-circle btn-sm
            pull-right
            m-l-10
          "
          @click="actionRightSidebar"
        >
          <i class="ti-settings text-white"></i>
        </button>
        <button
          :class="[
            'btn',
            'pull-right',
            { 'btn-sm btn-circle': isMobile },
            'btn-success',
          ]"
          @click="addLink"
          v-if="
            !state.isAdd &&
            !state.isEdit &&
            !state.isDetail &&
            !state.isDisposisi &&
            !!addLink &&
            !forbidden
          "
        >
          <i class="ti-pencil text-white"></i>
          {{ isMobile ? "" : "Tambah" }}
        </button>
      </div>
    </div>
    <div
      :class="['right-sidebar', { 'shw-rside': showRightSidebar }]"
      style="display: block"
    >
      <div
        class="slimScrollDiv"
        style="position: relative; overflow: hidden; width: auto"
      >
        <div class="slimscrollright" style="overflow: hidden; width: auto">
          <div class="rpanel-title">
            References Panel
            <span @click="actionRightSidebar">
              <i class="ti-close right-side-toggle"></i>
            </span>
          </div>
          <div class="r-panel-body">
            <ul class="m-t-20 chatonline scrolled">
              <li v-for="(row, index) in menuPermissions" :key="index">
                <router-link :to="row.path">{{ row.title }}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="slimScrollBar"
          style="
            background: rgb(220, 220, 220);
            width: 5px;
            position: absolute;
            top: 0px;
            opacity: 0.4;
            display: none;
            border-radius: 7px;
            z-index: 99;
            right: 1px;
            height: 395.101px;
          "
        ></div>
        <div
          class="slimScrollRail"
          style="
            width: 5px;
            height: 100%;
            position: absolute;
            top: 0px;
            display: none;
            border-radius: 7px;
            background: rgb(51, 51, 51);
            opacity: 0.2;
            z-index: 90;
            right: 1px;
          "
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    header: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    state: {
      required: true,
      type: Object,
    },
    addLink: {
      required: false,
      type: Function,
    },
    forbidden: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      showRightSidebar: false,
      menu: [
        {
          title: "Unit Management",
          path: "/unit",
          name: "unit",
        },
        {
          title: "Jabatan Management",
          path: "/jabatan",
          name: "position",
        },
        {
          title: "User Management",
          path: "/user",
          name: "user",
        },
        {
          title: "User Group Management",
          path: "/groups",
          name: "role",
        },

        {
          title: "Unit Group",
          path: "/unitGroup",
          name: "unitGroup",
        },
        {
          title: "Room Management",
          path: "/rooms",
          name: "just_hide_it",
        },
        {
          title: "Facilities Management",
          path: "/facilities",
          name: "just_hide_it",
        },

        {
          title: "Indeks Surat Management",
          path: "/indeksSurat",
          name: "just_hide_it",
        },
        {
          title: "Jenis Surat Management",
          path: "/jenisSurat",
          name: "document_type",
        },
        {
          title: "Instruksi Disposisi",
          path: "/instruksiDisposisi",
          name: "instruksiDisposisi",
        },
        {
          title: "Klasifikasi Surat Management",
          path: "/clasification",
          name: "clasification",
        },
        {
          title: "Klasifikasi Dokumen",
          path: "/clasificationdoc",
          name: "clasification_doc",
        },
        {
          title: "Pengumuman",
          path: "/information",
          name: "information",
        },
        {
          title: "Fasilitas Ruangan",
          path: "/fasilitas",
          name: "fasilitas",
        },
        {
          title: "Template Management",
          path: "/templateDokumen",
          name: "document_template",
        },
        
        {
          title: "Jenis Management",
          path: "/jenisDokumen",
          name: "just_hide_it",
        },
        {
          title: "Tipe Surat",
          path: "/typeSurat",
          name: "type",
        },
        {
          title: "Template Surat",
          path: "/templateSurat",
          name: "templateSurat",
        },
        {
          title: "Dokumen Pergub",
          path: "/dokumenPergub",
          name: "dokumenPergub",
        },
        {
          title: "Dokumen Manual",
          path: "/dokumenManual",
          name: "dokumenManual",
        },
        {
          title: "Kode Klasifikasi",
          path: "/kodeKlasifikasi",
          name: "kodeKlasifikasi",
        },
        {
          title: "Jenis Arsip",
          path: "/jenisArsip",
          name: "jenisArsip",
        },
        {
          title: "Kategori Tujuan",
          path: "/kategoriTujuan",
          name: "just_hide_it",
        },
        {
          title: "Asal Surat",
          path: "/asalSurat",
          name: "origin",
        },
        {
          title: "Indeks",
          path: "/kodeSurat",
          name: "kodeSurat",
        },
        {
          title: "FaQ",
          path: "/faQ",
          name: "faq",
        },
        {
          title: "Layanan Informasi",
          path: "/layananInformasi",
          name: "questions",
        },

        {
          title: "Retensi Arsip",
          path: "/retensiArsip",
          name: "retensi_arsip",
        },
      ],
      menuAllowed: [],
    };
  },
  methods: {
    actionRightSidebar() {
      this.showRightSidebar = !this.showRightSidebar;
    },
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    user() {
      return this.$store.state.profile.user;
    },
    menuPermissions() {
      // eslint-disable-next-line
      this.menuAllowed = [];
      if (this.$store.state.profile.permissions) {
        this.menu.forEach((element) => {
          var permisions = Object.assign(
            {},
            this.$store.state.profile.permissions[element.name]
          );
          if (permisions.read) {
            // eslint-disable-next-line
            this.menuAllowed.push(element);
          }
        });
      }
      return this.menuAllowed;
    },
    isAdmin() {
      // const admin = JSON.parse(localStorage.getItem("user_group_id"));
      // return admin === 1;
      let admin = false;
      for (let us in this.user.role) {
        if (this.user.role[us].id === 1) {
          admin = true;
        }
      }
      return admin;
    },
  },
};
</script>
<style>
.scrolled {
  max-height: 100vh;
  overflow-y: scroll;
  scrollbar-width: thin;
  height: 100%;
}
.scrolled::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.scrolled::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.scrolled::-webkit-scrollbar-thumb {
  border-radius: 10px;
  overflow: hidden;
}

/* Handle on hover */
.scrolled::-webkit-scrollbar-thumb:hover {
  background: #c3c6ca;
  overflow-y: scroll;
}
</style>
